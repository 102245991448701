import React from "react";
import logo1 from "../pic/f_logo1.png";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={logo1} alt="Milestone Inc. Logo" />
          </div>
          <div className="footer-links">
            <h4>Quick Links</h4>
            <ul>
              <li><a href="#about">About Us</a></li>
              <li><a href="#services">Services</a></li>
              <li><a href="#portfolio">Portfolio</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>
          <div className="footer-social">
            <h4>Follow Us</h4>
            <div className='social-buttons'>
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className='social-icon'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href="https://www.instagram.com/your-profile" target="_blank" rel="noopener noreferrer" className='social-icon'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className='social-icon'>
                <i className='fab fa-linkedin-in'></i>
              </a>
              <a href="https://x.com" target="_blank" rel="noopener noreferrer" className='social-icon'>
                <i className='fab fa-x'></i>
              </a>
            </div>
          </div>
          <div className="footer-contact">
            <h4>Contact Us</h4>
            <p>Email: richard.musyoka@milestone.com</p>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© 2024. All rights reserved by Milestone Inc.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
