import React from "react"
import "./Home.css"
import hero from "../pic/hero.png"
import skill1 from "../pic/skill1.png"
import skill2 from "../pic/skill2.png"
import skill3 from "../pic/skill3.png"
import { Typewriter } from "react-simple-typewriter"

const Home = () => {
  return (
    <>
      <section className='hero' id='home'>
        <div className='container f_flex top'>
          <div className='left top'>
            <h3>WELCOME TO MY WORLD</h3>
            <h1>
              Hi, I’m <span>Richard Musyoka</span>
            </h1>
            <h2>
              a
              <span>
                <Typewriter words={[" Senior Software Engineer.", " Mobile App Developer.", " Integrations Expert.", " Aviation Lover."," Web Portal Developer."]} loop cursor cursorStyle='|' typeSpeed={70} deleteSpeed={50} delaySpeed={1000} />
              </span>
            </h2>

            <p> I use my expertise in animation and design to create intuitive and engaging experiences for users. By integrating motion in thoughtful ways, I simplify interactions and enhance the overall user experience. My passion lies not just in adding visual appeal but in crafting functional and seamless interfaces that make technology accessible and enjoyable for everyone. Whether it's through innovative mobile apps or cutting-edge web solutions, I am committed to pushing the boundaries of what's possible in the digital realm.</p>

                  <div className='hero_btn d_flex'>
            <div className='col_1'>
              <h4>FIND WITH ME</h4>
              <div className='button'>
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                  <button className='btn_shadow'>
                    <i className='fab fa-facebook-f'></i>
                  </button>
                </a>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                  <button className='btn_shadow'>
                    <i className='fab fa-instagram'></i>
                  </button>
                </a>
                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                  <button className='btn_shadow'>
                    <i className='fab fa-linkedin-in'></i>
                  </button>
                </a>
                <a href="https://x.com" target="_blank" rel="noopener noreferrer">
                  <button className='btn_shadow'>
                    <i className='fab fa-x'></i>
                  </button>
                </a>
              </div>
            </div>
            <div className='col_1'>
              <h4>BEST SKILL ON</h4>
              <a href="https://github.com" target="_blank" rel="noopener noreferrer">
                <button className='btn_shadow'>
                  <img src={skill1} alt='Skill 1' />
                </button>
              </a>
              <a href="https://github.com" target="_blank" rel="noopener noreferrer">
                <button className='btn_shadow'>
                  <img src={skill2} alt='Skill 2' />
                </button>
              </a>
              <a href="https://github.com" target="_blank" rel="noopener noreferrer">
                <button className='btn_shadow'>
                  <img src={skill3} alt='Skill 3' />
                </button>
              </a>
            </div>
          </div>
          </div>
          <div className='right'>
            <div className='right_img'>
              <img src={hero} alt='' />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
