import portfolio1 from "../pic/portfolio-01.jpg";
import portfolio2 from "../pic/portfolio-02.jpg";
import portfolio3 from "../pic/portfolio-03.jpg";
import portfolio4 from "../pic/portfolio-04.jpg";
import portfolio5 from "../pic/portfolio-05.jpg";
import portfolio6 from "../pic/portfolio-06.jpg";

const Portfolio_data = [
  {
    id: 1,
    category: "DEVELOPMENT",
    totalLike: "600",
    title: "The services provide for design ",
    image: portfolio1,
  },
  {
    id: 2,
    category: "APPLICATION",
    totalLike: "750",
    title: "Mobile app landing design & maintain",
    image: portfolio2,
  },
  {
    id: 3,
    category: "PHOTOSHOP",
    totalLike: "630",
    title: "Logo design creativity & Application ",
    image: portfolio3,
  },
  {
    id: 4,
    category: "FIGMA",
    totalLike: "360",
    title: "Mobile app landing design & Services",
    image: portfolio4,
  },
  {
    id: 5,
    category: "WEB DESIGN",
    totalLike: "280",
    title: "Design for technology & services",
    image: portfolio5,
  },
  {
    id: 6,
    category: "WEB DESIGN",
    totalLike: "690",
    title: "App for technology & services",
    image: portfolio6,
  },
];

export default Portfolio_data;
