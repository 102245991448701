import slide1 from "../pic/slide-1.jpg";
import slide2 from "../pic/slide-2.jpg";
import slide3 from "../pic/slide-3.jpg";
import slide4 from "../pic/slide-4.jpg";
import slide5 from "../pic/slide-5.jpg";

const TestimonialApi = [
  {
    id: 1,
    image: slide1,
    design: "NCD - DESIGN",
    name: "Mevine Thoda",
    offcer: "Marketing Officer",
    post: "CEO - Marketing",
    date: "Thoda Department - Mar 4, 2018 - Aug 30, 2021",
    desc: "Working with this team was a game-changer for our marketing strategy. Their innovative design approach helped us capture our target audience effectively and improve our brand visibility. The attention to detail and commitment to excellence were evident throughout the project, making a significant impact on our marketing efforts.",
  },
  {
    id: 2,
    image: slide2,
    design: "Default name",
    name: "Davei Luace",
    offcer: "Chief Operating Manager",
    post: "Android App Development",
    date: "via Upwork - Mar 4, 2015 - Aug 30, 2021",
    desc: "The Android app developed by this team exceeded our expectations. Their expertise in app development and problem-solving skills were crucial in delivering a high-quality product on time. The communication was clear, and they provided valuable insights throughout the development process, resulting in a seamless user experience.",
  },
  {
    id: 3,
    image: slide3,
    design: "Rainbow-Themes",
    name: "Nevine Acotanza",
    offcer: "Chief Operating Officer",
    post: "Android App Development",
    date: "via Upwork - Mar 4, 2015 - Aug 30, 2021",
    desc: "The professionalism and technical skills demonstrated by this team were remarkable. They tackled complex challenges with ease and delivered an Android app that met all our requirements. Their proactive approach and dedication ensured a smooth development process and a final product that stands out in the market.",
  },
  {
    id: 4,
    image: slide4,
    design: "Bound - Trolola",
    name: "Jone Duone Joe",
    offcer: "Operating Officer",
    post: "Web App Development",
    date: "Upwork - Mar 4, 2016 - Aug 30, 2021",
    desc: "Our web app project was in excellent hands with this team. Their ability to understand our needs and translate them into a functional and user-friendly application was impressive. They provided valuable feedback and adjustments throughout the development process, resulting in a final product that aligns perfectly with our business goals.",
  },
  {
    id: 5,
    image: slide5,
    design: "Glassfisom",
    name: "Nevine Dhawan",
    offcer: "CEO Of Officer",
    post: "Android App Design",
    date: "Fiver - Mar 4, 2015 - Aug 30, 2021",
    desc: "The design work provided was outstanding. The team’s creativity and expertise were evident in every aspect of the Android app design. They not only met but exceeded our expectations with their innovative solutions and attention to detail. The end result is a design that enhances user engagement and delivers a superior experience.",
  },
]

export default TestimonialApi
