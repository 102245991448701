const ResumeApi = [
  // existing items
  {
    id: 1,
    category: "education",
    year: "Murang'a University (2018 - 2022)",
    title: "Bsc. in Software Engineering",
    desc: "I graduated with a Second Class Honors (Upper Division).",
    rate: "4.30/5",
  },
  {
    id: 2,
    category: "education",
    year: "St Paul's Kyaume Secondary School (2014 - 2017)",
    title: "Certificate",
    desc: "I graduated with a B+ grade earning the Kenya certificate of Secondary School Education.",
    rate: "4.30/5",
  },
  {
    id: 3,
    category: "education",
    year: "Tala Boys Primary School (2005 - 2013)",
    title: "Certificate",
    desc: "I graduated with an A- grade earning the Kenya certificate of Primary School Education.",
    rate: "4.30/5",
  },
  {
    id: 4,
    category: "experience",
    year: "Certificate in Cyber Security (2022 - 2023)",
    title: "Certificate in Cyber Security",
    desc: "Earned a Certificate in Cyber Security from Cisco Labs",
    rate: "4.70/5",
  },
  {
    id: 5,
    category: "experience",
    year: "Attain Enterprise Solutions (2022 - Current)",
    title: "Senior Systems Developer",
    desc: "I build cutting-edge software aimed at solving problems across the world.",
    rate: "4.95/5",
  },
  {
    id: 6,
    category: "experience",
    year: "Works at Pavel Technologies (2016 - 2020)",
    title: "Lead Software Developer",
    desc: "Led a team of software developers to build solutions to technological problems.",
    rate: "5.00/5",
  },
 
]

export default ResumeApi;
